<x-data-table [view]="view">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>

  <ng-template xDataColumnDef="subscriber" let-row>
    <x-subscriber-pill
      *ngIf="row.subscriber"
      [subscriber]="row.subscriber"
      [matMenuTriggerFor]="subscriberMenu"
      [matMenuTriggerData]="{ $implicit: row.subscriber }"
    ></x-subscriber-pill>
  </ng-template>

  <ng-template xDataColumnDef="subscriberAddress" let-row>
    <x-address-pill
      *ngIf="row.subscriber"
      [address]="row.subscriber.shippingAddress"
    ></x-address-pill>
  </ng-template>

  <ng-container *xDataColumnDef="'tags'; let row">
    <x-subscriber-tag-pill
      *ngFor="let tag of row.subscriber.tags"
      [tag]="tag"
    ></x-subscriber-tag-pill>
  </ng-container>

  <ng-template xDataColumnDef="order" let-row>
    <x-order-pill
      *ngIf="row.order"
      [order]="row.order"
      [matMenuTriggerFor]="orderMenu"
      [matMenuTriggerData]="{ $implicit: row.order }"
    ></x-order-pill>
  </ng-template>

  <mat-menu #orderMenu>
    <ng-template matMenuContent let-order>
      <a mat-menu-item [routerLink]="['/admin/orders', order.id, 'detail']">View Order</a>
    </ng-template>
  </mat-menu>

  <mat-menu #subscriberMenu>
    <ng-template matMenuContent let-subscriber>
      <a mat-menu-item [routerLink]="['/admin/users/detail', subscriber.user.id]">View User</a>
      <a mat-menu-item (click)="editSubscriber(subscriber)">Edit Subscriber</a>
    </ng-template>
  </mat-menu>

  <ng-template xDataColumnDef="subscriberPaymentMethod" sortable="true" let-row>
    <x-user-payment-method-pill
      [userPaymentMethod]="row.subscriber.userPaymentMethod"
    ></x-user-payment-method-pill>
  </ng-template>

  <ng-template xDataColumnDef="orderPayment" let-row>
    <x-order-payment-pill *ngIf="row.order" [order]="row.order"></x-order-payment-pill>
  </ng-template>

  <ng-template xDataColumnDef="orderPayments" let-row>
    <x-pill-group *ngIf="row.order?.payments">
      <x-payment-pill
        *ngFor="let payment of row.order.payments"
        [payment]="payment"
      ></x-payment-pill>
    </x-pill-group>
  </ng-template>

  <ng-template xDataColumnDef="orderTotal" let-row>
    <span *ngIf="row.order">{{ row.order.total | money: row.order.currency }}</span>
  </ng-template>

  <ng-template xDataColumnDef="shipment" let-row>
    <x-shipment-pill *ngIf="row.order" [shipment]="row.order.shipment"></x-shipment-pill>
  </ng-template>

  <ng-template xDataColumnDef="errorMessage" sortable="true" let-row>
    <div class="mat-hint" style="max-width: 300px; white-space: normal">
      {{ row.errorMessage }}
    </div>
  </ng-template>

  <ng-template xDataColumnDef="notifications" let-row>
    <x-pill-group *ngIf="row">
      <x-notification-pill
        *ngFor="let notification of row.notifications"
        [notification]="notification"
      ></x-notification-pill>
    </x-pill-group>
  </ng-template>

  <x-timestamp-column *xDataColumnDef="'timestamps'; let row" [row]="row"></x-timestamp-column>

  <button
    x-data-button
    *xDataBulkAction="'sms'"
    label="Notify"
    icon="message"
    color="primary"
    [matMenuTriggerFor]="smsMenu"
  ></button>
  <mat-menu #smsMenu>
    <ng-template matMenuContent>
      <button mat-menu-item (click)="sendSms(smsTypes.Custom)">Custom SMS</button>
      <button mat-menu-item (click)="sendSms(smsTypes.Deduction)">Deduction SMS</button>
      <button mat-menu-item (click)="sendSms(smsTypes.Delivery)">Delivery SMS</button>
      <button mat-menu-item (click)="sendSms(smsTypes.Paid)">Paid SMS</button>
      <!-- <button mat-menu-item (click)="sendSms(smsTypes.PaymentProcessing)">
        Payment Processing SMS
      </button> -->
      <button mat-menu-item (click)="sendSms(smsTypes.Unpaid)">Unpaid SMS</button>
      <button mat-menu-item (click)="sendEmail(entryEmailTypes.PaymentRequest)">
        Request Subscriber Payment Update
      </button>
    </ng-template>
  </mat-menu>
  <button
    x-data-button
    *xDataBulkAction="'activate'"
    label="Process Entry"
    icon="play"
    color="primary"
    (click)="processEntries()"
  ></button>
  <button
    x-data-button
    *xDataBulkAction="'activate'"
    label="Process Payment"
    icon="hand-holding-dollar"
    color="primary"
    (click)="processEntriesPayment()"
  ></button>
</x-data-table>
