import { NgModule } from '@angular/core';
import { ChannelSharedModule } from '../channel/channel-shared.module';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { MembershipsSharedModule } from '../memberships/memberships-shared.module';
import { OrdersSharedModule } from '../orders/orders-shared.module';
import { ProductsSharedModule } from '../products/products-shared.module';
import { UsersSharedModule } from '../users/users-shared.module';
import { SubscriberFormDialogComponent } from './components/subscriber-form-dialog/subscriber-form-dialog.component';
import { SubscriptionDialogComponent } from './components/subscription-dialog/subscription-dialog.component';
import { SubscriptionEntryTableComponent } from './components/subscription-entry-table/subscription-entry-table.component';
import { SubscriptionMembershipBenefitDialogComponent } from './components/subscription-membership-benefit-dialog/subscription-membership-benefit-dialog.component';
import { SubscriptionRunDialogComponent } from './components/subscription-run-dialog/subscription-run-dialog.component';
import { SubscriptionRunTableComponent } from './components/subscription-run-table/subscription-run-table.component';
import { SubscriptionVariantDialogComponent } from './components/subscription-variant-dialog/subscription-variant-dialog.component';
import { SubscriptionVariantProductQuantityDialogComponent } from './components/subscription-variant-product-quantity-dialog/subscription-variant-product-quantity-dialog.component';
import { SubscriptionDialogService } from './services/subscription-dialog.service';

@NgModule({
  declarations: [
    SubscriptionDialogComponent,
    SubscriptionVariantDialogComponent,
    SubscriptionVariantProductQuantityDialogComponent,
    SubscriptionRunTableComponent,
    SubscriptionMembershipBenefitDialogComponent,
    SubscriptionEntryTableComponent,
    SubscriberFormDialogComponent,
    SubscriptionRunDialogComponent,
  ],
  imports: [
    EcommerceAdminCoreModule,
    ChannelSharedModule,
    UsersSharedModule,
    ProductsSharedModule,
    OrdersSharedModule,
    MembershipsSharedModule,
  ],
  providers: [SubscriptionDialogService],
  exports: [SubscriptionRunTableComponent, SubscriptionEntryTableComponent],
})
export class SubscriptionsSharedModule {}
