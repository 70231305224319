import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { ISubscriptionRunRowObject } from '@x/ecommerce/domain-client';
import { SubscriptionRunFilterInput } from '@x/schemas/ecommerce';
import { lastValueFrom } from 'rxjs';
import { SubscriptionDialogService } from '../../services/subscription-dialog.service';

@Component({
  selector: 'x-subscription-run-table',
  templateUrl: './subscription-run-table.component.html',
  styleUrls: ['./subscription-run-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionRunTableComponent implements OnInit {
  @Input()
  view: DataTableView<ISubscriptionRunRowObject, SubscriptionRunFilterInput, any, number>;

  constructor(
    private promptDialogs: PromptDialogService,
    private subscriptionDialogs: SubscriptionDialogService,
  ) {}

  ngOnInit(): void {}

  generateRun() {
    this.promptDialogs
      .confirm({
        title: 'subscriptionRun.prompt.generateRun.title',
        actions: [
          {
            name: 'subscriptionRun.prompt.generateRun.yes',
            result: true,
          },
          {
            name: 'subscriptionRun.prompt.generateRun.no',
            result: false,
          },
        ],
      })
      .subscribe((result) => {
        if (!result) return;
      });
  }

  async openEditDialog(subscriptionRun: ISubscriptionRunRowObject) {
    await lastValueFrom(
      this.view.mutate(() =>
        this.subscriptionDialogs.openSubscriptionRunEditDialog(subscriptionRun.id).afterClosed(),
      ),
    );
  }
}
