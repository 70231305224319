<x-data-table [view]="view" [selectable]="false">
  <ng-template xDataColumnDef="id" sortable="true" let-row>
    {{ row.id }}
  </ng-template>

  <ng-template xDataColumnDef="name" let-row>
    <b>{{ row.name }}</b>
  </ng-template>

  <ng-template xDataColumnDef="entries" let-row>
    <x-pill>{{ row.entryCount }}</x-pill>
  </ng-template>

  <ng-template xDataColumnDef="orders" let-row>
    <x-pill>{{ row.orderCount }}</x-pill>
  </ng-template>

  <ng-template xDataColumnDef="paidOrders" let-row>
    <x-pill>{{ row.paidOrderCount }}</x-pill>
  </ng-template>

  <ng-template xDataColumnDef="pendingOrders" let-row>
    <x-pill>{{ row.pendingOrderCount }}</x-pill>
  </ng-template>

  <ng-template xDataColumnDef="cancelledOrders" let-row>
    <x-pill>{{ row.cancelledOrderCount }}</x-pill>
  </ng-template>

  <ng-template xDataColumnDef="variant" let-row>
    {{ row.variant.name }}
  </ng-template>

  <ng-template xDataColumnDef="salesTotal" let-row>
    <span *ngFor="let item of row.salesTotals">
      <x-pill>{{ item.total | money: item.currency }}</x-pill>
    </span>
  </ng-template>

  <ng-template xDataColumnDef="runInterval" let-row>
    {{ row.runStart | datetime }} - {{ row.runEnd | datetime }}
  </ng-template>

  <x-timestamp-column *xDataColumnDef="'timestamps'; let row" [row]="row"></x-timestamp-column>

  <a
    x-data-button
    label="View Run"
    icon="magnifying-glass"
    *xDataAction="'detail'; let row; primary: true"
    [routerLink]="['/admin/subscriptions/runs', row.id]"
  ></a>

  <button
    x-data-button
    *xDataAction="'edit'; let row; primary: true"
    label="Edit Options"
    icon="edit"
    (click)="openEditDialog(row)"
  ></button>
</x-data-table>
