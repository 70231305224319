import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import {
  ISubscriberRowObject,
  ISubscriptionEntryRowObject,
  SubscriberService,
  SubscriptionEntryService,
  SubscriptionRunService,
} from '@x/ecommerce/domain-client';
import {
  SubscriptionEntryFilterInput,
  SubscriptionEntryNotificationType,
  SubscriptionVariantSmsType,
} from '@x/schemas/ecommerce';
import { firstValueFrom } from 'rxjs';
import { SubscriptionDialogService } from '../../services/subscription-dialog.service';

@Component({
  selector: 'x-subscription-entry-table',
  templateUrl: './subscription-entry-table.component.html',
  styleUrls: ['./subscription-entry-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionEntryTableComponent implements OnInit {
  readonly smsTypes = SubscriptionVariantSmsType;
  readonly entryEmailTypes = SubscriptionEntryNotificationType;

  @Input()
  view: DataTableView<ISubscriptionEntryRowObject, SubscriptionEntryFilterInput, any, number>;

  constructor(
    private promptDialogs: PromptDialogService,
    private subscriptionRunService: SubscriptionRunService,
    private subscriptionEntryService: SubscriptionEntryService,
    private subscriberService: SubscriberService,
    private subscriptionDialogService: SubscriptionDialogService,
  ) {}

  ngOnInit(): void {}

  async processEntries() {
    const result = await firstValueFrom(
      this.promptDialogs.confirm({
        title: 'Process Entries',
        message:
          'Are you sure you want to process the selected entries? ' +
          'This will generate orders for the selected entries and attempt to checkout the order for payment.',
      }),
    );

    if (!result) {
      return;
    }

    this.view
      .mutateSelectedBatched((entryIds) => this.subscriptionRunService.processEntries(entryIds), {
        batchSize: 1,
      })
      .subscribe();
  }

  async processEntriesPayment() {
    const result = await firstValueFrom(
      this.promptDialogs.confirm({
        title: 'Process Payments',
        message: 'Are you sure you want to process payments for the selected entries?',
      }),
    );

    if (!result) {
      return;
    }

    this.view
      .mutateSelectedBatched(
        (entryIds) => this.subscriptionRunService.processEntriesPayment(entryIds),
        { batchSize: 1 },
      )
      .subscribe();
  }

  async sendSms(smsVariant: SubscriptionVariantSmsType) {
    this.view
      .mutateSelectedBatched(
        (entryIds) => this.subscriptionEntryService.sendSmses(entryIds, smsVariant),
        { batchSize: 5 },
      )
      .subscribe();
  }

  async sendEmail(type: SubscriptionEntryNotificationType) {
    this.view
      .mutateSelectedBatched(
        (entryIds) => this.subscriptionEntryService.sendNotification(entryIds[0], type),
        { batchSize: 1 },
      )
      .subscribe();
  }

  async editSubscriber(subscriber: ISubscriberRowObject) {
    const result = await firstValueFrom(
      this.subscriptionDialogService
        .openSubscriberUpdateDialog({
          id: subscriber.id,
        })
        .afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateRow(subscriber.id, () =>
        this.subscriberService.update({ id: subscriber.id, ...result }),
      )
      .subscribe();
  }
}
